import React, { useState, useEffect } from "react";
import "./login.scss";
import ShopezLogo from "../../assets/images/shopez_logo.png";
import ShopezBack from "../../assets/images/arrow_left_alt.svg";
import Logins from "./Loginillustration.png";
import LoginsOtp from "./Otpverification.png";
import LogoLogin from "./LoginIcon.svg";
import LogoOtp from "./otpIcon.svg";
import { useHistory } from "react-router-dom";
import Button from "../../components/button/button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import { getMethod, postMethod, postMethodWithPlatformWithoutToken } from "../../utils/services/api";
import urls from "../../utils/services/apiUrl.json";
import { setStoreId, setUserSession, setUserRole, getUserRole, setUserOnBoarding } from "../../utils/common";
import LoadingOverlay from "react-loading-overlay";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import OtpInput from 'react-otp-input';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

function Login() {

    let steps = "";
    const [state, setState] = useState({ username: "", password: "" });
    const [signUpForm, setSignUpForm] = useState(false);
    const [loader, setLoader] = useState(false);
    const [onBoarding, setOnBoarding] = useState(false);
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [loginScreenVisible, setLoginScreenVisible] = useState(true);
    const [otpValue, setOtpValue] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [countInvalid, setCountInvalid] = useState(0);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleEmailChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value.trim(),
        }));
    };

    const onBoardingStatus = async () => {
        await getMethod(urls.verifyOnboardStatus).then((response) => {
            if (response.status === 200) {
                setOnBoarding(response.data.status);
                setUserOnBoarding(response.data.status);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const handleSubmit = (event) => {
        setOtpValue("");
        if (state.username !== "" && state.password !== "") {
            setLoader(true);
            let body = {
                username: state.username.toLowerCase(),
                password: btoa(state.password),
                role: btoa('vendor')
            };

            postMethodWithPlatformWithoutToken(urls.login, body, 'merchant').then((response) => {
                if (response.status === 200) {
                    setLoader(false);
                    toast.success(response.data.response)
                    setLoginScreenVisible(false)
                } else {
                    setLoader(false);
                    toast.error(response.data.error);
                }
            }).catch((error) => {
                setLoader(false);
            });
        }
    };

    const verifiedOtp = async () => {
        let body = {
            username: state.username.toLowerCase(),
            otp: otpValue
        }

        postMethod(urls.verifyLoginOtp, body).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.success)
                setStoreId(response.data.store_id);
                setUserRole(response.data.role_type);
                setUserSession(response.data.token);
                onBoardingStatus();
                window.$store_name = response.data.store_name;

                if (response.data.role_type === "super-admin") {
                    history.push("/onlineenquiry")
                } else if (onBoarding === true && steps === 5) {
                    history.push("/home")
                } else {
                    getSteps()
                }
            } else {
                toast.error(response.data.error)

                let countNumber = countInvalid + 1;
                setCountInvalid(countNumber);

                if (countNumber >= 3) {
                    setLoginScreenVisible(true)
                    sessionStorage.clear();
                    setOtpValue('');
                }
            }
        }).catch((error) => {
            setLoader(false);
        });
    }

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                steps = response.data.signup_step;
                if (steps === 1) {
                    history.push("/addProduct")
                } else if (steps === 2) {
                    history.push("/financials")
                } else if (steps === 3) {
                    history.push("/home")
                } else if (steps === 4) {
                    history.push("/review")
                } else if (steps === 5) {
                    history.push("/home")
                } else {
                    history.push("/aboutStore");
                }
            }
        });
    };

    const handleOtpChange = (e) => {
        setOtpValue(e)
    }

    const createstore = async () => {
        history.push("/createstore");
    };

    const gotoBack = async () => {
        history.push("/");
    };

    const gotoForgotPassword = async () => {
        if (state.username !== "") {
            let body = {
                email: state.username,
            };
            await postMethodWithPlatformWithoutToken(urls.linkForForgetPassword, body, 'merchant').then((response) => {
                if (response.status === 200) {
                    toast.success("Link Has Been Sent To Your Email To Set Password");
                } else {
                    toast.error(response.data.error);
                }
            });
        } else {
            toast.error("Please Enter Username");
        }
    };

    const gotoBackback = async () => {
        history.goBack()
    };

    return (
        <LoadingOverlay active={loader} spinner text="Processing...">
            <div className="container-fluid p-0 m-0">
                {loginScreenVisible ?
                    <div className="outerDiv row p-0 m-0">
                        <div className="col-sm-12 col-lg-6 forget_left">
                            <div className="LoginPasswordDeal container"
                                style={{ background: `url(${Logins})`, backgroundRepeat: 'no-repeat', backgroundSize: 'calc(100% - 40px) calc(100% - 56px)', backgroundPosition: '20px 30px' }}
                            >
                                <img className="logo_home img-fluid" src={ShopezLogo} />
                                <img className="logo_forgot img-fluid mobile-setting" src={Logins} />
                            </div>
                        </div>
                        <div className="loginForm col-sm-12 col-lg-6 p-0 ">
                            <div id="create-card" className="login_card_container pb-5">
                                <div className="container ">
                                    <div className="text-center img_div mr-5"><img className="new_img_logo text-center img-fluid" src={LogoLogin} /></div>
                                    <div className="heading-title text-center mb-3">Login</div>
                                    {/* <div className="h6-label text-center" style={{ color: "#808080" }}>
                                        Don't have an account?&nbsp;
                                        <span style={{ color: "#4472C4", textAlign: "end", cursor: "pointer" }} className="h6-label" onClick={() => createstore()}>
                                            Create your Store
                                        </span>
                                    </div> */}
                                    <div className=" mt-1">
                                        <div className="">
                                            <div className="h6-label text-center" style={{ color: "#808080", fontWeight: 600, fontSize: "16px" }}>Please enter your credentials to proceed further</div>
                                        </div>
                                    </div>

                                    <ValidatorForm>
                                        <div className="">
                                            <div className="">
                                                <div className="text_mng first_div_top">
                                                    <TextValidator
                                                        errorMessages={"Please Enter A Valid Email"}
                                                        validators={[
                                                            "isEmail",
                                                            "required",
                                                        ]}
                                                        onChange={handleEmailChange}
                                                        value={state.username}
                                                        name="username"
                                                        label="Username"
                                                        variant="outlined"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mb-1">
                                                <div className="text_mng_lr">
                                                    <TextValidator
                                                        required
                                                        errorMessages={"Please Enter The Password"}
                                                        validators={["required"]}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 20 }}
                                                        type={showPassword ? "text" : "password"}
                                                        name="password"
                                                        label="Password"
                                                        variant="outlined"
                                                        value={state.password}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton>
                                                                        {showPassword ?
                                                                            <VisibilityIcon onClick={() => setShowPassword(!showPassword)} />
                                                                            :
                                                                            <VisibilityOffIcon onClick={() => setShowPassword(!showPassword)} />
                                                                        }
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />

                                                </div>
                                            </div>

                                            <div className="">
                                                <div className="text-mng">
                                                    <div style={{ color: "#4472C4", textAlign: "end", cursor: "pointer" }} className="h6-label" onClick={() => gotoForgotPassword()}>Forgot password?</div>
                                                </div>
                                            </div>

                                            <div className="">
                                                <div className="text-mng-submit_div">
                                                    <Button
                                                        validatorListener={(value) => console.log("value")}
                                                        onClick={handleSubmit}
                                                        className="btn btn-primary w-100 text-truncate"
                                                        name="Log In"
                                                    />
                                                </div>
                                            </div>
                                            <div className="h6-label text-center" style={{ color: "#808080", marginTop: "-25px", fontWeight: 600, fontSize: "16px" }}>Don't have an account?&nbsp;<span style={{ color: "#4472C4", textAlign: "end", cursor: "pointer", fontWeight: 600, fontSize: "16px" }} className="h6-label" onClick={() => createstore()}>Create your Store</span></div>
                                        </div>
                                    </ValidatorForm>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="outerDiv row p-0 m-0">
                        <div className="col-sm-12 col-lg-6 forget_left">
                            <div className="LoginPasswordDeal container"
                                style={{ background: `url(${LoginsOtp})`, backgroundRepeat: 'no-repeat', backgroundSize: 'calc(100% - 40px) calc(100% - 56px)', backgroundPosition: '20px 30px' }} >
                                <img className="logo_home img-fluid" src={ShopezLogo} />
                                <img className="logo_forgot img-fluid mobile-setting" src={LoginsOtp} />
                            </div>
                        </div>
                        <div className="loginForm p-0  col-sm-12 col-lg-6 login_otp_card_container">
                            <div onClick={() => gotoBackback()} className="back_set_other" style={{ cursor: "pointer" }}>
                                <img className="back_other img-fluid" src={ShopezBack} />
                            </div>
                            <div id="create-card" className="login_card_container pb-5">
                                <div className="container">
                                    <div className="text-center img_div"><img className="new_img_logo text-center img-fluid" src={LogoOtp} /></div>
                                    <div className=" mt-3">
                                        <div className="">
                                            <div className="heading-title text-center mb-3">OTP verification</div>
                                        </div>
                                    </div>
                                    <div className=" mt-1">
                                        <div className="">
                                            <div className="h6-label-tagline text-center" >Please enter the OTP to verify your phone number</div>
                                        </div>
                                    </div>

                                    <ValidatorForm>
                                        <div className=" ">
                                            <div className=" ">
                                                <div className="forgot_password otp_max_width otp-input text_mng_lr mb-1 first_div_top_otp">
                                                    <OtpInput
                                                        value={otpValue}
                                                        onChange={(e) => handleOtpChange(e)}
                                                        numInputs={6}
                                                        className="opt_box"
                                                    />

                                                    {/* <div style={{ color: "#4472C4", textAlign: "end", cursor: "pointer", marginTop: "25px",marginRight:"13px" }} className="h5-label" onClick={() => handleSubmit()}>
                                                        Resend OTP
                                                    </div> */}

                                                </div>
                                                <div className="h5-label-resend heading-reset" style={{ cursor: "pointer" }} onClick={() => handleSubmit()}>Resend OTP</div>
                                            </div>
                                        </div>
                                        {/*<div className="muiResendOtp" onClick={() => resendOtp()}>
                                            Resend OTP?
                                        </div>*/}

                                        {/* <div id="recaptcha-container"></div> */}
                                        <div className=" ">
                                            <div className="">
                                                <div className="d-flex justify-content-center ">
                                                    <Button id="sign-in-button" onClick={() => verifiedOtp()} className="btn btn-primary-verify" name="Verify" style={{ width: "50vw" }}></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </ValidatorForm>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </LoadingOverlay>
    );
}
export default Login;
