import React, { useState } from "react";
import "./Products.scss";
import NoImage from "../../../../../../../../../assets/images/storelisting.png";

const Products = () => {
    const [expandIndex, setExpandIndex] = useState("");
    let productDetails = window.$ReviewStoreDetails.store_detail[0].product;

    const expandDropdown = (index) => {
        if (expandIndex === index) {
            setExpandIndex("");
        } else {
            setExpandIndex(index);
        }
    };

    return (
        <div>
            <div className="row mt-3 pt-3 pb-3 mr-0 ml-0">
                <div className="col-2 f-600"></div>
                <div className="col-3 f-600">Product Name</div>
                <div className="col-2 f-600">SKU</div>
                <div className="col-1 f-600">Qty.</div>
                <div className="col-1 f-600">Cost</div>
                <div className="col-2 f-600">Price</div>
                <div className="col-1 f-600">Inv Alert</div>
            </div>

            {productDetails && productDetails.map((item, index) => {
                return (
                    <>
                        <div key={index + 1} className="row ft-14 mt-3 pt-3 pb-3 mr-0 ml-0" style={{ background: "#ECECEC" }}>
                            <div className="col-2 d-flex" style={{ alignItems: "center", cursor: "pointer" }}>
                                {item.children.length > 0 && expandIndex !== index ? (
                                    <i style={{ marginRight: "10px", fontSize: "20px", verticalAlign: "middle" }} className="fa fa-angle-down iconColorArrow" onClick={() => expandDropdown(index)} aria-hidden="true"></i>
                                ) : item.children.length > 0 && expandIndex === index ? (
                                    <i style={{ marginRight: "10px", fontSize: "20px", verticalAlign: "middle" }} className="fa fa-angle-up iconColorArrow" onClick={() => expandDropdown(index)} aria-hidden="true"></i>
                                ) : null}
                                {item.images && item.images.slice(0, 1).map((item, i) => {
                                    return (
                                        <img className="listedImg" src={item.original} alt="" />
                                    );
                                })}

                                {typeof json_data !== "undefined" && item.images.length === 0 &&
                                    <img className="listedImg" src={NoImage} alt="" />
                                }
                            </div>
                            <div className="col-3 d-flex" style={{ alignItems: "center" }}>{productDetails[index].product_name}</div>
                            <div className="col-2 d-flex" style={{ alignItems: "center" }}>{productDetails[index].partner_sku}</div>
                            <div className="col-1 d-flex" style={{ alignItems: "center" }}>{productDetails[index].num_in_stock}</div>
                            <div className="col-1 d-flex" style={{ alignItems: "center" }}>{productDetails[index].invoice_cost}</div>
                            <div className="col-2 d-flex" style={{ alignItems: "center" }}>{productDetails[index].price}</div>
                            <div className="col-1 d-flex" style={{ alignItems: "center" }}>{productDetails[index].low_stock_threshold}</div>
                        </div>

                        {productDetails[index].children.length > 0 && expandIndex === index && productDetails[index].children.map((items, index) => {
                            return (
                                <div key={index + 1} className="row ft-14 mt-3 pt-3 pb-3 mr-0 ml-0" style={{ background: "#ECECEC" }}>
                                    <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                        {items.images && items.images.slice(0, 1).map((items, i) => {
                                            return (
                                                <img className="listedImg" src={items.original} alt="" />
                                            );
                                        })}
                                        {typeof json_data !== "undefined" && items.images.length === 0 &&
                                            <img className="listedImg" src={NoImage} alt="" />
                                        }
                                    </div>
                                    <div className="col-3 d-flex" style={{ alignItems: "center" }}>{items.product_name}</div>
                                    <div className="col-2 d-flex" style={{ alignItems: "center" }}>{items.partner_sku}</div>
                                    <div className="col-1 d-flex" style={{ alignItems: "center" }}>{items.num_in_stock}</div>
                                    <div className="col-1 d-flex" style={{ alignItems: "center" }}>{items.invoice_cost}</div>
                                    <div className="col-2 d-flex" style={{ alignItems: "center" }}>{items.price}</div>
                                    <div className="col-1 d-flex" style={{ alignItems: "center" }}>{items.low_stock_threshold}</div>
                                </div>
                            );
                        })}
                    </>
                );
            })}
            {productDetails.length == 0 &&
                <div className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#F6F6F6", textAlign: "center", alignItems: "center", height: "75px" }}>
                    <div className="col-12 " >No Products Found.</div>
                </div>
            }
        </div>
    );
};

export default Products;
