import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyC-SDEgY8MjyWfiJY7lT7GuSAGRJ0SPORY",
    authDomain: "joyoffusion-ankit.firebaseapp.com",
    databaseURL: "https://shopez-a796b.firebaseio.com",
    projectId: "joyoffusion-ankit",
    storageBucket: "joyoffusion-ankit.appspot.com",
    messagingSenderId: "984819763200",
    appId: "1:984819763200:web:3d71905b974972e7d330e5"

};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {analytics};