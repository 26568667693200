import React, { useEffect, useState } from "react";
import "./setPassword.scss";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ShopezLogo from "../../../assets/images/shopez_logo.png";
import setPasswords from "./setPassword.png";
import Button from "../../../components/button/button";
import { useHistory } from "react-router-dom";
import Logoset from "./logo_Set.svg";
import { postMethod } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { toast } from "react-toastify";
import { setStoreId, setUserSession, setUserRole } from "../../../utils/common";
import Eye from "../../../assets/images/eye.png";
import HideEye from "../../../assets/images/hideEye.png";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from "@material-ui/core/IconButton";
import ErrorImage from "../../../assets/images/404Image.png"

const SetPassword = () => {
    let regex = new RegExp(
        "^(?!.*([A-Za-z0-9])\\1{2})(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&])([A-Za-z0-9$@$!%*?&]).{7,15}$"
    );

    const [getPassword, setPassword] = useState({
        password: "",
        confirm_password: "",
    });

    const [urlTokenId, setUrlTokenId] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [status, setStatus] = useState("")
    const history = useHistory();

    let url = new URL(window.location.href);

    let token = url.searchParams.get("token");
    let email = url.searchParams.get("email").replace(' ', '+');
    let userName = url.searchParams.get("owner_name");

    useEffect(() => {
        validateToken();
    }, []);

    const validateToken = async () => {
        let body = {
            signup_token: token,
        };
        await postMethod(urls.validateSetPassword, body).then((response) => {
            if (response.status === 200) {
                setUrlTokenId(response.data.store_meta_id);
                setStatus(response.status)
            } else if (response.status === 400) {
                // toast.error(response.data.response);
                setStatus(response.status)
            } else {
                history.push("/invalidToken");
            }
        }).catch((error) => {
            console.log(error)
        });
    };

    console.log(status)

    const continueToAboutStore = async () => {
        let body = {
            email: email,
            store_meta_id: urlTokenId,
            username: email,
            password: btoa(getPassword.password),
            role: "vendor",
        };

        if (
            getPassword.password !== "" &&
            getPassword.confirm_password !== "" &&
            getPassword.password === getPassword.confirm_password &&
            checkPassword() === true
        ) {
            await postMethod(urls.createProfile, body).then((response) => {
                if (response.status === 200) {
                    setUserSession(response.data.key);
                    setStoreId(response.data.store_id);
                    setUserRole("vendor");
                    window.$store_name = response.data.store_name;
                    history.push("/aboutStore");
                } else {
                    toast.error(response.data.response);
                }
            }).catch((error) => {
                console.log(error)
            });
        }
    };

    const checkPassword = () => {
        return regex.test(getPassword.password);
    };

    const createstore = async () => {
        history.push("/createstore");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPassword((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <React.Fragment>
            <div className="container-fluid p-0 m-0">
                <div className="row p-0 m-0">
                    <div className="col-sm-12 col-lg-6 forget_left">
                        <div className="setPasswordDeal container"
                            style={{
                                background: `url(${setPasswords})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'calc(100% - 40px) calc(100% - 56px)',
                                backgroundPosition: '20px 30px',
                                // backgroundSize: 'cover',
                            }} >
                            <img className="logo_home img-fluid" src={ShopezLogo} />
                            <img className="logo_forgot img-fluid mobile-setting" src={setPasswords} />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  pr-0">
                        {status === 200 ?
                            <div className="loginForm">
                                <div id="create-card" className="set_card_container create_welcome_card_container pd-5" >{ /* pt-5  */}
                                    <div className="container">
                                        <div className="text-center img_div"><img className="new_img_logo text-center img-fluid" src={Logoset} /></div>
                                        <h2 className="heading-title text-center mb-3">Set your Password</h2>
                                        <div className="h6-label text-center" style={{ color: "#808080" }}>Don't have an account?&nbsp;
                                            <span style={{ color: "#4472C4", cursor: "pointer" }} onClick={() => createstore()}>Create your Store</span>
                                        </div>
                                        <p className="h6-label text-center">Please enter your credentials to proceed further</p>
                                        <ValidatorForm>
                                            <div className="">
                                                <div className="text_mng first_div_top">
                                                    <TextValidator
                                                        required
                                                        errorMessages={"Please Enter A Valid Email"}
                                                        validators={[
                                                            "isEmail",
                                                            "required",
                                                        ]}
                                                        value={email}
                                                        name="User Name"
                                                        label="User Name"
                                                        variant="outlined"
                                                    />
                                                </div>
                                            </div>

                                            <div className="">
                                                <div className="text_mng text_mng_lr">
                                                    <TextValidator
                                                        required
                                                        errorMessages={"Please Enter The Password"}
                                                        validators={["minStringLength:1", "required"]}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 20 }}
                                                        type={showPassword ? "text" : "password"}
                                                        name="password"
                                                        label="Password"
                                                        variant="outlined"
                                                        value={getPassword.password}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton>
                                                                        {showPassword ?
                                                                            <VisibilityIcon onClick={() => setShowPassword(!showPassword)} />
                                                                            :
                                                                            <VisibilityOffIcon onClick={() => setShowPassword(!showPassword)} />
                                                                        }
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <div>{checkPassword()}</div>
                                                {checkPassword() === false && getPassword.password !== "" ? (
                                                    <div style={{ marginLeft: "3vw", fontSize: "14px", color: "Red", marginTop: 0, padding: 0 }}>Password must contain at least 8 characters,one capital letter,<br />one special character, one small character and one number.
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="">
                                                <div className="text_mng text_mng_lr">
                                                    <TextValidator
                                                        required
                                                        errorMessages={"Please Enter The Confirm Password"}
                                                        validators={[
                                                            "minStringLength:1",
                                                            "maxStringLength:30",
                                                            "required",
                                                        ]}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 20 }}
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        name="confirm_password"
                                                        label="Confirm Password"
                                                        variant="outlined"
                                                        value={getPassword.confirm_password}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton>
                                                                        {showConfirmPassword ?
                                                                            <VisibilityIcon onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                                                            :
                                                                            <VisibilityOffIcon onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                                                        }
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ margin: 0, padding: 0 }}>
                                                {getPassword.password !== getPassword.confirm_password && getPassword.confirm_password.length > 0 ? (
                                                    <div style={{ marginLeft: "3vw", fontSize: "14px", color: "Red", height: "25px" }}>Password and confirm password doesn't match!</div>
                                                ) : (
                                                    <div style={{ marginLeft: "3vw", fontSize: "14px", height: "25px" }}>

                                                    </div>
                                                )}
                                                <div>
                                                    <div style={{ margin: "0 35px 52px 35px" }}>
                                                        {getPassword.password !== getPassword.confirm_password && getPassword.confirm_password.length > 0 ?
                                                            <Button className="btn btn-secondary w-100 text-truncate" disabled={true} name="Continue"></Button>
                                                            :
                                                            <Button onClick={() => continueToAboutStore()} className="btn btn-primary w-100 text-truncate" name="Continue"></Button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </ValidatorForm>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="loginForm">
                                <div id="create-card" className="set_card_container-error create_welcome_card_container pd-5">
                                    <div className="container">
                                        <div className="heading_error">400 Error</div>
                                        <div className="error_text">This Link is Alredy Used</div>
                                        <div className="d-flex justify-content-center pt-3">
                                            <img src={ErrorImage} alt="ErrorImage" style={{ height: "55vh" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SetPassword;
