import React, { useState, useEffect } from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import "./attributes.scss";
import close from "../../../../../assets/images/close.png";
import Button from "../../../../../components/button/button";
import info from "../../../../../assets/images/info.png";
import closeIcon from "../../../../../assets/images/closeIcon.svg";
import { Tooltip } from 'antd';
import Switch from "react-switch";
import { getProductId, getProductEditableFlag, setAddonsEditedFlag, getAddonsEditedFlag } from "../../../../../utils/common";
import { getMethod, postMethodWithToken, putMethodWithToken } from "../../../../../utils/services/api";
import urls from "../../../../../utils/services/apiUrl.json";
import SpinnerLoader from "../../../../../components/loader/spinnerLoader";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { toast } from "react-toastify";
import OnHoldModal from "../../../../../components/OnHoldModal/onHoldModal";

function Attributes({ attributesData, prevAttributesData }) {
    var panelOpen = window.$panelActive;
    const initialValue = [];

    const [loader, setLoader] = useState(false);

    const [fields, setFields] = useState([{ variantType: '', variantValue: '', defaultVariantValue: '' }]);
    const [colorFields, setColorFields] = useState([{ variantType: "color", variantValue: '', defaultVariantValue: '' }]);
    const [addOns, setAddOns] = useState([{ add_on: '' }]);
    const [getAddOnsList, setGetAddOnsList] = useState([]);
    const [addColor, setAddColor] = useState(false);
    const [editableFlag, setEditableFlag] = useState(true);
    const [modalMe, setModalMe] = useState(false)


    useEffect(() => {
        getSteps()
        if (prevAttributesData) {
            setColorFields(prevAttributesData.colorFields)
            setFields(prevAttributesData.fields)
            setAddColor(prevAttributesData.addColor)
            setAddOns(prevAttributesData.addOns)
            setLoader(true);
            setEditableFlag(prevAttributesData.editableFlag !== undefined ? prevAttributesData.editableFlag : true)
        } else {
            if (getProductId() && getProductEditableFlag() !== 'added') {
                attributeDetails(getProductId());
                addonsDetails(getProductId())
            } else {
                setLoader(true);
            }
        }

        getAddonsListData();
    }, []);

    useEffect(() => {
        let backData = {
            colorFields: colorFields,
            fields: fields,
            addColor: addColor,
            editableFlag: editableFlag,
            addOns: addOns,
            is_submit: false,
            is_skip: ''
        }
        attributesData(backData)
    }, [colorFields, fields, addColor, editableFlag, addOns])

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    };

    const getAddonsListData = async () => {
        await getMethod(urls.getAddonsList).then((response) => {
            setGetAddOnsList(response.data.response)
        }).catch((error) => {
            console.log("API error", error);
        });
    }

    const attributeDetails = async (curId) => {
        const attrName = [];
        const attrValue = [];
        const attrNameColor = [];
        const attrValueColor = [];
        const colorAttrDetails = [];
        const attrDetails = [];

        await getMethod(urls.getAttribute + curId + "/").then((response) => {

            if (response.status === 200) {
                setLoader(true);
                if (response.data.isInventory !== false) {
                    setEditableFlag(false)
                }

                response.data.attributes.length > 0 && response.data.attributes.map((item) => {

                    if (item.value_text !== "color_code_swatch" && item.name !== "color") {
                        if (!attrName.includes(item.name)) {
                            attrName.push(item.name);
                            attrValue[item.name] = [];
                        }

                        if (!attrValue[item.name].includes(item.value_text)) {
                            attrValue[item.name].push(item.value_text)
                        }
                    } else if (item.name === "color") {

                        if (!attrNameColor.includes(item.name)) {
                            attrNameColor.push(item.name);
                            attrValueColor[item.name] = [];
                        }

                        if (!attrValueColor[item.name].includes(item.value_text)) {
                            attrValueColor[item.name].push(item.value_text)
                        }
                    }
                });

                if (attrNameColor.length > 0) {
                    attrNameColor.forEach((x, i) => {
                        let data = {
                            variantType: attrNameColor.map(item => item).join(','),
                            variantValue: attrValueColor[x].map(item => item).join(','),
                            defaultVariantValue: attrValueColor[x].map(item => item).join(','),
                        }

                        colorAttrDetails.push(data)
                    });
                    setColorFields(colorAttrDetails)

                    setAddColor(true);
                } else if (response.data.isInventory !== false) {
                    setColorFields([])
                    setAddColor(false)
                }

                if (attrName.length > 0) {
                    attrName.forEach((x, i) => {
                        let data = {
                            variantType: x,
                            variantValue: attrValue[x].map(item => item).join(','),
                            defaultVariantValue: attrValue[x].map(item => item).join(','),
                        }

                        attrDetails.push(data)
                    });
                    setFields(attrDetails)
                } else if (response.data.isInventory !== false) {
                    setFields([])
                }
            }
        }).catch((error) => {
            console.log("API error", error);
        });

        setLoader(true);
    };

    const addonsDetails = async (curId) => {
        await getMethod(urls.getAddons + "?product_id=" + curId).then((response) => {
            if (response.status === 200) {
                if (response.data.response.length > 0 && response.data.response.addons.length > 0) {
                    let getAddonData = [];
                    response.data.response.addons.forEach((item, value) => {
                        let addllData = {
                            add_on: item.id,
                        }

                        getAddonData.push(addllData);
                    });

                    setAddOns(getAddonData)
                    setAddonsEditedFlag('editable')
                } else {
                    setAddonsEditedFlag('added')
                }
            }
        }).catch((error) => {
            console.log("API error", error);
        });
    }

    function handleAdd() {
        setFields([...fields, { variantType: '', variantValue: '', defaultVariantValue: '' }]);
    }

    function handleAddColor() {
        setColorFields([{ variantType: "color", variantValue: '', defaultVariantValue: '' }]);
    }

    function handleAddOns() {
        setAddOns([...addOns, { add_on: '' }]);
    }

    function handleChange(e, index) {
        const { name, value } = e.target;
        const list = [...new Set(fields)];

        if (editableFlag !== true) {
            if (value.length < list[index].defaultVariantValue.length) {
                list[index][name] = list[index].defaultVariantValue;
                setFields(list);
            } else {
                list[index][name] = value;
                setFields(list);
            }
        } else {
            list[index][name] = value;
            setFields(list);
        }
    }

    function handleChangeColor(e, index) {
        const { name, value } = e.target;

        const colorList = [...new Set(colorFields)];
        if (editableFlag !== true) {
            if (value.length < colorList[index].defaultVariantValue.length) {
                colorList[index][name] = colorList[index].defaultVariantValue;
                setColorFields(colorList);
            } else {
                colorList[index][name] = value;
                setColorFields(colorList);
            }
        } else {
            colorList[index][name] = value;
            setColorFields(colorList);
        }
    }

    const handleAddonCharges = (e, index) => {
        const { name, value } = e.target;
        const list = [...new Set(addOns)];
        list[index][name] = value;
        setAddOns(list);
    }

    function handleRemove(i) {
        const values = [...fields];
        values.splice(i, 1);
        setFields(values);
    }

    function handleRemoveColor(i) {
        const values = [...colorFields];
        values.splice(i, 1);
        setColorFields(values);
    }

    function handleRemoveAddOns(i) {
        const values = [...addOns];
        values.splice(i, 1);
        setAddOns(values);
    }

    const updateTags = async (index, item, variantType, notDeleteFlag) => {
        if (notDeleteFlag !== true) {
            if (variantType === 'color') {
                let val = colorFields[index].variantValue.split(",");
                const indexOfItem = val.indexOf(item);
                val.splice(indexOfItem, 1);
                colorFields[index].variantValue = val.toString();
                setColorFields([...colorFields]);
            } else {
                let val = fields[index].variantValue.split(",");
                const indexOfItem = val.indexOf(item);
                val.splice(indexOfItem, 1);
                fields[index].variantValue = val.toString();
                setFields([...fields]);
            }
        }
    };

    const adddColor = () => {
        setAddColor(!addColor);
        if (addColor) {
            window.$ColorToggle = false;
        } else {
            window.$ColorToggle = true;
        }

        if (fields.length > 0 && fields[0].variantType === null && fields[0].variantValue === null && addColor === false) {
            const values = [...colorFields];
            values.splice(0, 1);
            setColorFields(values);
            handleAddColor();
        } else if (fields.length == 0 && addColor === false) {
            handleAddColor();
        } else {
            setColorFields([{ variantType: "color", variantValue: null }]);
        }
    };

    const closeColor = () => {
        setAddColor(false);
        handleRemoveColor();
        window.$ColorToggle = false;
    };

    const existingSubmitVariant = () => {
        let attData = [];

        if (colorFields.length > 0 && colorFields[0].variantType !== '' && colorFields[0].variantValue !== '' && colorFields[0].variantValue !== null) {
            colorFields.forEach((item, value) => {
                let colorData = {
                    name: item.variantType,
                    values: item.variantValue.split(',')
                }

                attData.push(colorData);
            });
        }

        if (fields.length > 0 && fields[0].variantType !== '' && fields[0].variantValue !== '' && fields[0].variantValue !== null) {
            fields.forEach((item, value) => {
                let fieldData = {
                    name: item.variantType,
                    values: item.variantValue.split(',')
                }

                attData.push(fieldData);
            });
        }

        if (attData.length > 0) {
            attributesData(attData)
        }
    }

    const functionCheck = () => {
        const length = fields.length;
        for (let i = 0; i < length; i++) {
            for (let j = i + 1; j < length; j++) {
                if (fields?.[i]?.variantType === fields?.[j]?.variantType) {
                    toast.error("Please Do Not Fill Same Type Of Variant")
                    return false;
                }
            }
            if (fields?.[i]?.variantType === "color" && addColor) {
                toast.error("Please Do Not Fill Same Type Of Variant")
                return false;
            }
        }
        return true;
    }

    const onSubmitVaiants = async (value) => {
        if (value === 'skip') {
            let backData = {
                colorFields: colorFields,
                fields: fields,
                addColor: addColor,
                editableFlag: editableFlag,
                addOns: addOns,
                is_submit: false,
                is_skip: 'skip'
            }
            attributesData(backData)
        } else {

            let attData = [];

            if (colorFields.length > 0 && colorFields[0].variantType !== '' && colorFields[0].variantValue !== '' && colorFields[0].variantValue !== null) {
                colorFields.forEach((item, value) => {
                    let colorData = {
                        name: item.variantType,
                        values: item.variantValue.split(',')
                    }

                    attData.push(colorData);
                });
            }

            if (fields.length > 0 && fields[0].variantType !== '' && fields[0].variantValue !== '' && fields[0].variantValue !== null) {
                fields.forEach((item, value) => {
                    let fieldData = {
                        name: item.variantType,
                        values: item.variantValue.split(',')
                    }

                    attData.push(fieldData);
                });
            }

            let addOnsData = [];
            addOns.forEach((item, value) => {
                if (item.add_on) {
                    addOnsData.push(item.add_on);
                }
            });

            if (attData.length > 0 || addOnsData.length > 0) {
                if (attData.length > 0 && functionCheck()) {
                    let body = {
                        attributes: attData,
                    };

                    await postMethodWithToken(urls.variants, body).then((response) => {
                        if (response.status === 200 || response.status === 201) {
                            let backData = {
                                colorFields: colorFields,
                                fields: fields,
                                data: response.data,
                                addColor: addColor,
                                editableFlag: editableFlag,
                                addOns: addOns,
                                is_submit: true,
                                is_skip: 'noskip'
                            }
                            attributesData(backData)
                        }
                    }).catch((error) => {
                        console.log("error", error);
                    });
                }

                if (addOnsData.length > 0) {
                    let body = {
                        product_id: getProductId(),
                        addons: addOnsData
                    }
                    if (getProductId() && getAddonsEditedFlag() !== 'added') {
                        await putMethodWithToken(urls.putAddons, body).then((response) => {
                            if (response.status === 200 || response.status === 201) {
                                let backData = {
                                    colorFields: colorFields,
                                    fields: fields,
                                    data: response.data,
                                    addColor: addColor,
                                    editableFlag: editableFlag,
                                    addOns: addOns,
                                    is_submit: true,
                                    is_skip: 'noskip'
                                }
                                attributesData(backData)

                                setAddonsEditedFlag('editable')
                            }
                        }).catch((error) => {
                            console.log("error", error);
                        });
                    } else {
                        await postMethodWithToken(urls.postAddons, body).then((response) => {
                            if (response.status === 200 || response.status === 201) {
                                let backData = {
                                    colorFields: colorFields,
                                    fields: fields,
                                    data: response.data,
                                    addColor: addColor,
                                    editableFlag: editableFlag,
                                    addOns: addOns,
                                    is_submit: true,
                                    is_skip: 'noskip'
                                }
                                attributesData(backData)

                                setAddonsEditedFlag('editable')
                            }
                        }).catch((error) => {
                            console.log("error", error);
                        });
                    }
                }

            } else {

                let backData = {
                    colorFields: colorFields,
                    fields: fields,
                    data: '',
                    addColor: addColor,
                    editableFlag: editableFlag,
                    addOns: addOns,
                    is_submit: true,
                    is_skip: ''
                }
                attributesData(backData)
            }
        }
    }

    const wordTagInput = (variantValue, variantType, i, defaultVariantValue) => {
        let words = [];
        let defaultWords = [];

        if (variantValue && variantValue !== '' && variantValue !== null) {
            words = variantValue.split(",");
        }

        if (defaultVariantValue && defaultVariantValue !== '' && defaultVariantValue !== null) {
            defaultWords = defaultVariantValue.split(",");
        }

        if (words.length > 0) {
            return (
                words.map((item, index) => {
                    let notDeleteFlag = false;
                    if (defaultWords.includes(item) === true) {
                        notDeleteFlag = true
                    }
                    return (
                        <div key={index}
                            className="variantSelectedValueText"
                            style={{ background: variantType.toLowerCase() == "color" ? "#95A6FF" : variantType.toLowerCase() == "size" ? "#FFB753" : variantType.toLowerCase() == "material" ? "#C4C4C4" : "#FFC0CB " }}
                            onClick={() => updateTags(i, item, variantType, notDeleteFlag)}>
                            <div className="d-flex">
                                <div className="col-8 p-0 d-flex justify-content-center" style={{ alignItems: "center" }}>{item}</div>
                                {notDeleteFlag !== true &&
                                    <div style={{ cursor: "pointer" }} className="col-4 p-0 d-flex align-items-center justify-content-end">
                                        <img className="closeIcon" src={close} alt="" />
                                    </div>
                                }
                            </div>
                        </div>
                    );
                })
            );
        }
    };

    const handleAddonChargesValue = (value) => {
        if (value) {
            let getIndexOfItem = getAddOnsList.findIndex((data) => data.id == value);
            let addonCharge = getAddOnsList[getIndexOfItem].addons_charges

            return addonCharge;
        } else {
            return value;
        }
    }

    return (
        <div>
            {!loader ? (
                <SpinnerLoader />
            ) : (
                <div className="row mt-3">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-12">
                                <div className="h5-label">Variants</div>
                                <div className="variantsDescription pt-2">Add products variants if product is available in multiple variants like size, color, etc.</div>
                            </div>
                        </div>

                        {editableFlag === false && addColor !== false ?
                            <div className="row pt-3">
                                <div className="col-xl-10 col-lg-10">
                                    <div className="variantsDescription mt-1">Does the product have a color Attribute.</div>
                                </div>
                                <div className="col-xl-2 col-lg-2">
                                    <Switch
                                        checked={addColor}
                                        className={(addColor) ? 'selected_sett' : 'unselected_sett'}
                                        onColor="#00A739"
                                        checkedIcon={false}
                                        onChange={() => adddColor()}
                                        disabled={editableFlag === false ? true : false}
                                    />
                                </div>
                            </div>
                            : editableFlag === true &&
                            <div className="row pt-3">
                                <div className="col-xl-10 col-lg-10">
                                    <div className="variantsDescription mt-1">Does the product have a color Attribute.</div>
                                </div>
                                <div className="col-xl-2 col-lg-2">
                                    <Switch
                                        checked={addColor}
                                        className={(addColor) ? 'selected_sett' : 'unselected_sett'}
                                        onColor="#00A739"
                                        checkedIcon={false}
                                        onChange={() => adddColor()}
                                    />
                                </div>
                            </div>
                        }

                        <div>
                            {addColor !== false &&
                                <>
                                    {colorFields.map((y, j) => {
                                        return (
                                            <div className={addColor === false ? "row pt-2 hideColor" : "row pt-2 showColor"}>
                                                <div className="col-3">
                                                    <TextField
                                                        label="Variant"
                                                        name="variantType"
                                                        value={y.variantType || y.name}
                                                        variant="outlined"
                                                        disabled={editableFlag === false ? true : false}
                                                    />
                                                </div>
                                                <div className={editableFlag === false ? "col-8 pr-0" : "col-7 pr-0"}>
                                                    <TextField
                                                        value={y.variantValue}
                                                        onChange={(e) => handleChangeColor(e, j)}
                                                        className="br-left-top-bottom"
                                                        label="Value(s)"
                                                        name="variantValue"
                                                        variant="outlined"
                                                    />
                                                </div>
                                                <div className="col-1 p-0">
                                                    <Tooltip
                                                        placement="top"
                                                        trigger={["hover"]}
                                                        overlay={
                                                            <span>Enter variant values separated by comma. For e.g.Small, Medium, Large.</span>
                                                        }
                                                    >
                                                        <div className="info">
                                                            <a href="#">
                                                                <img src={info} alt="" />
                                                            </a>
                                                        </div>
                                                    </Tooltip>
                                                </div>

                                                {editableFlag !== false &&
                                                    <div className="col-1 p-0">
                                                        <div style={{ cursor: "pointer" }} className="removeVariant" onClick={() => closeColor()}>
                                                            <img className="closeIcon" src={closeIcon} alt="" />
                                                        </div>
                                                    </div>
                                                }

                                                <div className="col-12 mt-2">
                                                    <div className="row m-0">{wordTagInput(y.variantValue, y.variantType, j, y.defaultVariantValue)} </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            }
                        </div>

                        <div>
                            {fields.length > 0 && fields.map((x, i) => {
                                return (
                                    <div className="row pt-3">
                                        <div className="col-3">
                                            <TextField
                                                label="Variant"
                                                name="variantType"
                                                value={x.variantType || x.name}
                                                onChange={(e) => handleChange(e, i)}
                                                variant="outlined"
                                                disabled={editableFlag === false ? true : false}
                                            />
                                        </div>
                                        <div className={editableFlag === false ? "col-8 pr-0" : "col-7 pr-0"}>
                                            <TextField
                                                value={x.variantValue}
                                                onChange={(e) => handleChange(e, i)}
                                                className="br-left-top-bottom"
                                                label="Value(s)"
                                                name="variantValue"
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className="col-1 p-0">
                                            <Tooltip
                                                placement="top"
                                                trigger={["hover"]}
                                                overlay={
                                                    <span>Enter variant values separated by comma. For e.g. Small, Medium, Large.</span>
                                                }
                                            >
                                                <div className="info">
                                                    <a href="#">
                                                        <img src={info} alt="" />
                                                    </a>
                                                </div>
                                            </Tooltip>
                                        </div>
                                        {editableFlag !== false &&
                                            <div className="col-1 p-0">
                                                <div style={{ cursor: "pointer" }} className="removeVariant" onClick={() => handleRemove(i)}>
                                                    <img className="closeIcon" src={closeIcon} alt="" />
                                                </div>
                                            </div>
                                        }
                                        <div className="col-12 mt-2">
                                            <div className="row m-0">
                                                {wordTagInput(x.variantValue, x.variantValue, i, x.defaultVariantValue)}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {editableFlag === true &&
                            <div className="row pt-3">
                                <div className="col-12">
                                    <div style={{ marginTop: "70px" }}>
                                        <Button name="Add variants" onClick={() => handleAdd()} className="btn btn-cancle w-100" />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="col-6">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="h5-label">General Add-ons</div>
                                    <div className="variantsDescription pt-2">Enter the details of the add-ons options if available to a product.</div>
                                </div>
                            </div>

                            <div>
                                {addOns.map((x, i) => {
                                    return (
                                        <div className="row pt-3">
                                            <div className="col-6">
                                                <FormControl variant="outlined">
                                                    <InputLabel id="subcategory-select">Add-on</InputLabel>
                                                    <Select
                                                        MenuProps={{ autoFocus: false }}
                                                        labelId="product-add-on"
                                                        id="product-add-on-dropdown"
                                                        value={x.add_on}
                                                        defaultValue={x.add_on}
                                                        label="Add-on"
                                                        name="add_on"
                                                        onChange={(e) => handleAddonCharges(e, i)}
                                                    >
                                                        {getAddOnsList.length > 0 && getAddOnsList.map((item) => {
                                                            return (
                                                                <MenuItem key={item.id} value={item.id}>{item.addons_name}</MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-5 pr-0">
                                                <TextField
                                                    className="br-left-top-bottom"
                                                    value={getAddOnsList.length > 0 ? handleAddonChargesValue(x.add_on) : ''}
                                                    type="number"
                                                    //onChange={(e) => handleChangeAddonsNumber(e, i)}
                                                    //onBlur={(e) => onSubmitEditToAddDecimal(e, i)}
                                                    InputProps={{
                                                        inputProps: { min: 1 },
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        ),
                                                    }}
                                                    name="recycleValue"
                                                    label="Value"
                                                    variant="outlined"
                                                    disabled="true"
                                                />
                                            </div>
                                            <div className="col-1 p-0">
                                                <div style={{ cursor: "pointer" }} className="removeAddOns" onClick={() => handleRemoveAddOns(i)}>
                                                    <img className="closeIcon" src={closeIcon} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="row pt-3">
                                <div className="col-12">
                                    <div>
                                        <Button name="Add more Add-ons" onClick={() => handleAddOns()} className="btn btn-cancle w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-end" style={{ marginTop: "80px" }}>
                            <Button onClick={() => onSubmitVaiants("skip")} name="Skip" className="btn btn-cancle mb-5 mr-3" />
                            <Button onClick={() => onSubmitVaiants("variants")} name="Save" className="btn btn-primary mb-5" />
                        </div>

                    </div>
                    <OnHoldModal isOnHold={modalMe} />
                </div>
            )}
        </div>
    );
}
export default Attributes;
