import React, { useEffect, useState } from "react";
import "./collection.scss";
import Button from "../../../../components/button/button";
import TabCollection from "./components/tabCollection/tabCollection";
import CollectionDB from "../../../../assets/images/collection.png";
import CollectionList from "./components/collectionList/CollectionList";
import { getMethod } from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json"
import OnHoldModal from "../../../../components/OnHoldModal/onHoldModal";


function Collection(props) {
    window.$addCollection = false;
    const [addCollection, setAddCollection] = useState(window.$addCollection);
    const [collectionDetail, setCollectionDetail] = useState("");
    const [show, setShow] = useState(false);
    const [modalMe,setModalMe] = useState(false)

    useEffect(()=>{
        getSteps()
    },[])

    const collectionPage = () => {
        getSteps()
        setAddCollection(true);
        window.$addCollection = true;
        setShow(false);
        setCollectionDetail("");
    };

    const collectionDetails = (val) => {
        setCollectionDetail(val);
    }

    const showView = (val) => {
        setShow(val);
    }
    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setModalMe(response.data.on_hold_check_flag)    
            }
        });
    };

    return (
        <div className={props.menu ? "screenLeftMargin w-100" :"screenLeftMarginResponse w-100"}>

            {!addCollection &&
                <>
                    <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                        <div className="col-12 heading-title">Collections</div>
                    </div>
                    <div className="row ml-3 mr-3 mt-2 mobileResponsive">
                        <div className="col-lg-9 col-md-8">
                            <div className="muiDescripationInfo">Collection can be used to manage your listings and Discounts.This information will not be seen by customers.</div>
                        </div>
                        <div className="col-lg-3 col-md-4 d-flex justify-content-end btn-mobile-margin-none" style={{ top: "-25px" }}>
                            <Button onClick={() => collectionPage()} className="btn btn-cancle btn-lg" name="Create Collection"></Button>
                        </div>
                    </div>
                </>
            }

            {addCollection ?
                <div className="row ml-3 mr-3 mobileResponsive">
                    <div className="col-12">
                        < TabCollection show={show} showListing={collectionDetail} setShowListing={(val) => setAddCollection(val)} />
                    </div>
                </div>
                : ""}

            {!addCollection &&
                <div className="row ml-3 mr-3 mobileResponsive">
                    <div className="col-12">
                        < CollectionList show={(val) => showView(val)} setShowListing={(val) => setAddCollection(val)} collectionDetails={(val) => collectionDetails(val)} />
                        <OnHoldModal isOnHold={modalMe}/>
                    </div>
                </div>
            }

        </div>
    )
}
export default Collection;