import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import NoImage from "../../../assets/images/storelisting.png";
import "./campaign.scss";
import Button from "../../../components/button/button";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import moment from "moment";
import { getMethod, putMethodWithToken, deleteMethodWithTokenAndData } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { toast } from 'react-toastify';
import Pagination from '@mui/material/Pagination';

const Campaign = () => {
    const history = useHistory();
    const itemsPerPage = 20;
    const campaignTypes = ["Link based", "Association based", "Category based"];

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [campaignsList, setCampaignsList] = useState([]);
    const [openCampaignModel, setOpenCampaignModel] = useState(false);
    const [indexIdToDelete, setIndexIdToDelete] = useState("");
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getCampaignsList();
    }, []);

    const getCampaignsList = async () => {
        await getMethod(urls.getPromotions).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setCampaignsList(response.data.results.data);
            }
        });
    };

    const handlePageChange = async (event, value) => {
        setLoader(true);
        setPage(value)

        await getMethod(urls.getPromotions + "?page="+value).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setCampaignsList(response.data.results.data);
                setLoader(false);
            }
        });

        setLoader(false);
    }
    
    const goToDetails = (type, id) => {
        history.push({
            pathname: "./campaign/details",
            type: type,
            id: id
        });
    };

    const allStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 1100 ? 'translate(-30%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 1100 ? "35vw" : "25vw",
            height: screenSize.width <= 1100 ? "40vh" : "30vh",
        },
    };

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    const campaignStatusUpdate = async (id, status) => {
        let body = {
            is_active: status
        }

        await putMethodWithToken(urls.putPromotionStatus + id + '/', body).then((response) => {

            if (response.status === 201 || response.status === 200) {
                toast.success(response.data.response)
            } else {
                toast.error(response.data.response);
            }
        });

        getCampaignsList()
    }

    const ToDelete = (id) => {
        setConfirmationModal(true);
        setIndexIdToDelete(id);
    };

    const campaignDelete = async (id) => {
        let body = {
            is_deleted: true
        }

        await putMethodWithToken(urls.deletePromotion + id + '/', body).then((response) => {
            if (response.status === 201 || response.status === 200) {
                toast.success(response.data.response)
                setConfirmationModal(false);
            } else {
                toast.error(response.data.response);
            }
        });

        getCampaignsList()
    }
    

    const showCampaignListing = () => {
        return (
            <>
                <div className="col-12">
                    <div className="row mt-3">
                        <div className="col-5 f-600">Name of Campaign</div>
                        <div className="col-2 f-600">Campaign Type</div>
                        <div className="col-3 f-600">Country / State / City</div>
                        <div className="col-1 f-600">Status</div>
                        <div className="col-1 f-600">Actions</div>
                    </div>

                    {campaignsList.length > 0 && campaignsList.map((item, index) => {
                        return (
                            <>
                                <div key={index + 1} className="row mt-3 pt-3 pb-3" style={{background: "#ECECEC"}}>
                                    <div className="col-5 d-flex" style={{alignItems: "center", cursor: "pointer"}} onClick={() => goToDetails((item.bannerType === 'linked-based' ? 'Link' : (item.bannerType === 'category-based' ? 'Category' : 'Association')), item.id)}>
                                        <div className="d-flex">
                                            <img className="listedImg" src={item.bannerImage.file} alt="" />
                                        </div>
                                        <div className="ml-2">
                                            <div>{item.name}</div>
                                            <div className="d-flex">
                                                {item.weekdays.map((days) => {
                                                    return <div className="daysBlock">{days.name.substring(0, 2)}</div>;
                                                })}
                                            </div>
                                            <div className="campaignDate">
                                                {moment(item.startDate).format('YYYY MMM DD')} to {moment(item.endDate).format('YYYY MMM DD')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 d-flex" style={{alignItems: "center", cursor: "pointer"}} onClick={() => goToDetails((item.bannerType === 'linked-based' ? 'Link' : (item.bannerType === 'category-based' ? 'Category' : 'Association')), item.id)}>
                                        {(item.bannerType === 'linked-based' ? 'Link' : (item.bannerType === 'category-based' ? 'Category' : 'Association'))}
                                    </div>

                                    <div className="col-3 d-flex" style={{alignItems: "center", cursor: "pointer"}} onClick={() => goToDetails((item.bannerType === 'linked-based' ? 'Link' : (item.bannerType === 'category-based' ? 'Category' : 'Association')), item.id)}>
                                        {item.country} / {item.state} / {item.city}
                                    </div>

                                    <div className="col-1 d-flex" style={{alignItems: "center"}}>
                                        <Switch
                                            checked={item.isActive}
                                            className={ (item.isActive) ? 'selected_sett' : 'unselected_sett' }
                                            onColor="#00A739"
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            name="isActive"
                                            onChange={(event) => {
                                                campaignStatusUpdate(item.id, event)
                                            }}
                                        />
                                    </div>
                                    <div className="col-1 d-flex" style={{alignItems: "center"}}>
                                        <div className="d-flex">
                                            <EditIcon fontSize="small" onClick={() => goToDetails((item.bannerType === 'linked-based' ? 'Link' : (item.bannerType === 'category-based' ? 'Category' : 'Association')), item.id)} style={{ cursor: "pointer" }} />
                                        </div>
                                        <div className="d-flex ml-2">
                                            <DeleteIcon fontSize="small" style={{ cursor: "pointer",color:"#eb2813" }} onClick={() => ToDelete(item.id)} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </>
        );
    };

    return (
        <div className="screenLeftMargin w-100">
            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <h1 className="heading-title col-6">Campaign</h1>
                <div className="col-6 d-flex justify-content-end">
                    <Button onClick={() => setOpenCampaignModel(true)} className="btn btn-cancle btn-lg" name="Create Campaign"></Button>
                </div>
            </div>

            {count > 1 && 
                <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <Pagination 
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton 
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <div className="col-12">
                    {showCampaignListing()}
                </div>
            </div>

            {count > 1 && 
                <div className="row ml-3 mr-3 mt-3 mb-5 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <Pagination 
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton 
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            <Modal isOpen={openCampaignModel} style={allStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="">
                    <div onClick={() => setOpenCampaignModel(false)} className="crossModel">
                        <CloseIcon />
                    </div>
                    <div className="modalListing mt-4">
                        {campaignTypes.map((item) => {
                            return (
                                <div style={{cursor: "pointer"}} onClick={() => (item === "Link based" ? goToDetails("Link", "Create") : (item === "Category based" ? goToDetails("Category", "Create") : goToDetails("Association", "Create")))} className="typesCamp">{item}</div>
                            );
                        })}
                    </div>
                </div>
            </Modal>

            <Modal isOpen={confirmationModal} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <div className="col-12 text-center">Are you sure you want to delete this campaign?</div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => setConfirmationModal(false)} name="Cancel" className="btn btn-cancle btn-sm mr-3"/>
                            <Button type="onSubmit" onClick={() => { campaignDelete(indexIdToDelete);}} name="Delete" className="btn btn-primary btn-sm"/>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Campaign;
