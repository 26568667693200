import React, { useState, useEffect } from "react";
import "./customers.scss";
import Button from "../../../../components/button/button";
import { TextField } from "@material-ui/core";
import Modal from "react-modal";
import FormControl from "@material-ui/core/FormControl";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputMask from "react-input-mask";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import CollectionDB from "../../../../assets/images/collection.png";
import Edit from "../../../../assets/images/Edit_E.svg";
import Bin from "../../../../assets/images/Delete_D.svg";
import { toast } from "react-toastify";
import { deleteMethodWithToken, getMethod, postMethodWithToken, putMethodWithToken } from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json";
import SpinnerLoader from "../../../../components/loader/spinnerLoader";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Pagination from '@mui/material/Pagination';
import NoImage from "../../../../assets/images/storelisting.png";
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import { useHistory } from "react-router-dom";
import moment from "moment";
import OnHoldModal from "../../../../components/OnHoldModal/onHoldModal";


function Customers(props) {
    const history = useHistory();
    const itemsPerPage = 20;

    const [customerDetail, setCustomerDetail] = useState(false);
    const [listOfCustomer, setListOfCustomer] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    const [getCustomerDetails, setCustomerDetails] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        type: "",
        unit: "",
        address: "",
        city: "",
        postal_code: "",
        country: "",
        province: "",
    });
    const [getStatePovince, setStatePovince] = useState("State/Province");
    const [getPinZip, setGetPinZip] = useState("Postal code/Zip code");
    const [countrySelect, setCountrySelect] = useState("");
    const [typeSelect, setTypeSelect] = useState("");
    const [action, setAction] = useState("");
    const [loading, setLoading] = useState(false);
    const [idClicked, setIdClicked] = useState();
    const [confirmation, setConfirmation] = useState(false);
    const [indexIdToDelete, setIndexIdToDelete] = useState("");
    const [countryOptions, setCountryOptions] = useState([
        { value: "CA", label: "Canada" },
        { value: "US", label: "USA" },
    ]);
    const [typeOptions, setTypeOptions] = useState([
        { value: "HOME", label: "Home" },
        { value: "WORK", label: "Work" },
    ]);
    const [modalMe, setModalMe] = useState(false)

    const customerDetails = (Action) => {
        setAction(Action);
        setCustomerDetail(true);
    };

    const clearFields = () => {
        setCustomerDetails("");
        setCountrySelect("");
        setTypeSelect("");
    };

    const closeCustomerDetails = () => {
        setCustomerDetail(false);
        clearFields();
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setCustomerDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleEmailChange = (e) => {
        const { name, value } = e.target;
        setCustomerDetails((prevState) => ({
            ...prevState,
            [name]: value.trim(),
        }));
    };

    const deleteCustomer = async (id) => {
        setConfirmation(false);
        setLoading(true);
        await deleteMethodWithToken(urls.deleteCustomer + id).then((response) => {
            if (response.status === 200 || response.status === 201) {
                customerList();
                setLoading(false);
            }
        });
        setLoading(false);
    };

    const fillCustomerDetails = async (Action, id) => {
        setAction(Action);
        setIdClicked(id);
        await getMethod(urls.getCustomerDetail + id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                setCustomerDetails((prevState) => ({
                    ...prevState,
                    ["first_name"]: response.data.first_name,
                }));
                setCustomerDetails((prevState) => ({
                    ...prevState,
                    ["last_name"]: response.data.last_name,
                }));
                setCustomerDetails((prevState) => ({
                    ...prevState,
                    ["email"]: response.data.email,
                }));
                setCustomerDetails((prevState) => ({
                    ...prevState,
                    ["address"]: response.data.address,
                }));
                setCustomerDetails((prevState) => ({
                    ...prevState,
                    ["postal_code"]: response.data.postal_code,
                }));
                setCustomerDetails((prevState) => ({
                    ...prevState,
                    ["city"]: response.data.city,
                }));
                setCustomerDetails((prevState) => ({
                    ...prevState,
                    ["province"]: response.data.province,
                }));
                setCustomerDetails((prevState) => ({
                    ...prevState,
                    ["phone_number"]: response.data.contact_number,
                }));
                setCustomerDetails((prevState) => ({
                    ...prevState,
                    ["unit"]: response.data.unit,
                }));

                if (response.data.country === "CA") {
                    setStatePovince("Province");
                    setGetPinZip("Postal Code");
                } else {
                    setStatePovince("State");
                    setGetPinZip("ZIP Code");
                }
                setCountrySelect(response.data.country);
                setTypeSelect(response.data.type);
            }
        });
        setCustomerDetail(true);
    };

    const createCustomer = async () => {
        if (
            getCustomerDetails.first_name !== "" &&
            getCustomerDetails.last_name !== "" &&
            getCustomerDetails.email !== "" &&
            getCustomerDetails.address !== "" &&
            typeSelect !== "" &&
            getCustomerDetails.postal_code !== "" &&
            countrySelect !== "" &&
            getCustomerDetails.province !== "" &&
            getCustomerDetails.phone_number !== "" &&
            getCustomerDetails.first_name !== null &&
            getCustomerDetails.last_name !== null &&
            getCustomerDetails.address !== null &&
            typeSelect !== null &&
            getCustomerDetails.postal_code !== null &&
            getCustomerDetails.email !== null &&
            countrySelect !== null &&
            getCustomerDetails.province !== null &&
            getCustomerDetails.phone_number !== null
        ) {
            let body = {
                first_name: getCustomerDetails.first_name,
                last_name: getCustomerDetails.last_name,
                email: getCustomerDetails.email.toLowerCase(),
                contact_number: getCustomerDetails.phone_number,
                type: typeSelect,
                unit: getCustomerDetails.unit,
                address: getCustomerDetails.address,
                city: getCustomerDetails.city,
                postal_code: getCustomerDetails.postal_code,
                country: countrySelect,
                province: getCustomerDetails.province,
            };
            if (action === "create") {
                await postMethodWithToken(urls.createCustomer, body).then((response) => {
                    if(response.status === 200 || response.status === 201){
                        customerList();
                    }
                });
            } else if (action === "update") {
                await putMethodWithToken(urls.updateCustomerDetail + idClicked + "/", body).then((response) => {
                    customerList();
                });
            }
            setCustomerDetail(false);
            clearFields();
        } else {
            toast.error("Please Fill All Details");
        }
    };

    useEffect(() => {
        customerList();
        getSteps()
    }, []);

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    };


    const customerList = async () => {
        setLoading(true);
        await getMethod(urls.getCustomersList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setListOfCustomer(response.data.results.data)
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setLoading(false);
            }
        });
        setLoading(false);
    };

    const handlePageChange = async (event, value) => {
        setLoading(true);
        getSteps()
        setPage(value)

        await getMethod(urls.getCustomersList + "?page=" + value).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListOfCustomer(response.data.results.data);
                setLoading(false);
            }
        });

        setLoading(false);
    }

    const ToDelete = (id) => {
        setConfirmation(true);
        setIndexIdToDelete(id);
    };

    const handleCountryChange = (event) => {
        setCountrySelect(event.target.value);

        if (event.target.value === "CA") {
            setStatePovince("Province");
            setGetPinZip("Postal Code");
        } else {
            setStatePovince("State");
            setGetPinZip("ZIP Code");
        }
    };

    const handleTypeChange = (event) => {
        setTypeSelect(event.target.value);
    };

    const allStyles = {
        content: {
            marginBottom: "4vw",
            top: 0,
            left: "58vw",
            right: "auto",
            marginRight: "4vw",
            marginBottom: "3vw",
            transform: "translate(-50%, 10%)",
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "75vw",
            height: "90vh",
            outline: "none",
            padding: "15px",
        },
    };

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            width: "25vw",
            transform: "translate(-50%, -50%)",
        },
    };

    return (
        <div className={props.menu ? "screenLeftMargin w-100" : "screenLeftMarginResponse w-100"}>
            <div className="row ml-3 mr-3 mt-3">
                <div className="col-6">
                    <h1 className="heading-title">Customers</h1>
                </div>
                {/*<div className="col-6 d-flex justify-content-end">
                    <Button onClick={() => customerDetails("create")} className="btn btn-cancle" name="Add Customer"></Button>
                </div>*/}
            </div>
            {count > 1 &&
                <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <OnHoldModal isOnHold={modalMe} />
                            <Pagination
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            <div className='row ml-3 mr-3 pl-3 pr-3 mt-3 mobileResponsive'>
                <div className="col-12">
                    <div className="row">
                        <div className="col-3 f-600">Name</div>
                        <div className="col-3 f-600">Info</div>
                        <div className="col-3 f-600">Joined</div>
                        <div className="col-3 f-600">Last login</div>
                    </div>

                    {listOfCustomer.length > 0 ? listOfCustomer.map((item, index) => {
                        return (
                            <div className="row mt-3 pt-3 pb-3" style={{ background: "#F6F6F6" }}>
                                <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                    <div className="d-flex">
                                        <img src={item.profile_pic ? item.profile_pic.file : NoImage} className="listedRadiusImg" />
                                    </div>
                                    <div className="d-flex ml-2" style={{ alignItems: "center" }}>
                                        {item?.first_name} {item?.last_name}
                                    </div>
                                </div>

                                <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                    <div>
                                        <PhoneIcon fontSize="small" style={{ color: "#808080", marginRight: "5Px", marginTop: "-1px" }} />
                                        {item.contact_number}
                                    </div>
                                    {/*<div>
                                        <LanguageIcon fontSize="small" style={{color: "#808080", marginRight:"5Px", marginTop: "-1px"}}/>
                                        www.google.com
                                    </div>*/}
                                </div>

                                <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                    {moment(item?.joined).format('YYYY MMM DD HH:mm ')}
                                </div>
                                <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                    {item?.last_login ? moment(item.last_login).format('YYYY MMM DD HH:mm') : ''}
                                </div>
                            </div>
                        )
                    }) :
                        <div className="row mt-3 pt-4 pb-4" style={{ background: "#F6F6F6", display: "flex", justifyContent: "center", alignItems: "center" }}>No Customer found</div>
                    }
                    <OnHoldModal isOnHold={modalMe} />
                </div>
            </div>

            {count > 1 &&
                <div className="row ml-3 mr-3 mt-3 mb-5 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <OnHoldModal isOnHold={modalMe} />
                            <Pagination
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            <Modal isOpen={confirmation} style={customStyles}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <div className="col-12 text-center">Are you sure you want to delete this customer?</div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => setConfirmation(false)} name="Cancel" className="btn btn-cancle btn-sm mr-3" />
                            <Button type="onSubmit" onClick={() => { deleteCustomer(indexIdToDelete); }} name="Delete" className="btn btn-primary btn-sm" />
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={customerDetail} style={allStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="row">
                    <div className="col-10 offset-1">
                        <div className="row pt-3">
                            <div className="h4-label mt-3 mb-3 col-12">Enter Customer Details</div>
                        </div>

                        <div>
                            <ValidatorForm>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <TextValidator
                                            validators={[
                                                "required",
                                            ]}
                                            errorMessages={
                                                ("Please Fill First Name")
                                            }
                                            inputProps={{ maxLength: 20 }}
                                            name="first_name"
                                            label="First Name"
                                            variant="filled"
                                            onChange={handleFieldChange}
                                            value={getCustomerDetails.first_name}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TextValidator
                                            validators={[
                                                "required",
                                            ]}
                                            errorMessages={
                                                ("Please Fill Last Name")
                                            }
                                            inputProps={{ maxLength: 20 }}
                                            name="last_name"
                                            label="Last Name"
                                            variant="filled"
                                            onChange={handleFieldChange}
                                            value={getCustomerDetails.last_name}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <TextValidator
                                            validators={[
                                                "required",
                                                "isEmail",
                                            ]}
                                            errorMessages={
                                                ("Please Fill Email",
                                                    "Please Fill Valid Email")
                                            }
                                            name="email"
                                            label="E-Mail"
                                            variant="filled"
                                            onChange={handleEmailChange}
                                            value={getCustomerDetails.email}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <InputMask
                                            mask={"+1 (999) 999-9999"}
                                            maskChar="_"
                                            onChange={handleFieldChange}
                                            value={getCustomerDetails.phone_number}
                                            disabled={false}
                                        >
                                            {() => (
                                                <TextValidator
                                                    validators={[
                                                        "minStringLength:10",
                                                        "maxStringLength:20",
                                                        "required",
                                                    ]}
                                                    required
                                                    errorMessages={
                                                        ("Please Fill Valid Phone Number",
                                                            "Please Fill Valid Phone Number",
                                                            "Please Fill Phone Number")
                                                    }
                                                    inputProps={{ maxLength: 20 }}
                                                    name="phone_number"
                                                    label="Phone Number"
                                                    variant="filled"
                                                    onChange={handleFieldChange}
                                                    value={getCustomerDetails.phone_number}
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-4">
                                        <FormControl variant="filled">
                                            <InputLabel id="type-select">Type</InputLabel>
                                            <Select
                                                labelId="type-select"
                                                id="type-dropdown"
                                                onChange={handleTypeChange}
                                                value={typeSelect}
                                                label="Type"
                                                validators={[
                                                    "required",
                                                ]}
                                                errorMessages={
                                                    ("Please Fill Type")
                                                }
                                            >
                                                {typeOptions.map((item) => {
                                                    return (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-4">
                                        <TextValidator
                                            inputProps={{ maxLength: 20 }}
                                            name="unit"
                                            label="Unit"
                                            variant="filled"
                                            type="number"
                                            onChange={handleFieldChange}
                                            value={getCustomerDetails.unit}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <TextValidator
                                            validators={[
                                                "required",
                                            ]}
                                            errorMessages={
                                                ("Please Fill Address")
                                            }
                                            inputProps={{ maxLength: 20 }}
                                            name="address"
                                            label="Address"
                                            variant="filled"
                                            onChange={handleFieldChange}
                                            value={getCustomerDetails.address}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <TextValidator
                                            errorMessages={"Please Fill City"}
                                            validators={[
                                                "required",
                                            ]}
                                            required
                                            inputProps={{ maxLength: 20 }}
                                            name="city"
                                            label="City"
                                            variant="filled"
                                            onChange={handleFieldChange}
                                            value={getCustomerDetails.city}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <InputMask
                                            mask={getPinZip !== "Postal Code" ? "99999" : "a9a 9a9"}
                                            maskChar={null}
                                            onChange={handleFieldChange}
                                            value={getCustomerDetails.postal_code}
                                            disabled={false}
                                        >
                                            {() => (
                                                <TextValidator
                                                    validators={[
                                                        "minStringLength:4",
                                                        "maxStringLength:5",
                                                        "required",
                                                    ]}
                                                    required
                                                    errorMessages={
                                                        ("Please Fill Valid Postal Code/ZIP Code",
                                                            "Please Fill Valid Postal Code/ZIP Code",
                                                            "Please Fill Postal Code/ZIP Code")
                                                    }
                                                    inputProps={{ maxLength: 20 }}
                                                    name="postal_code"
                                                    label={getPinZip}
                                                    variant="filled"
                                                    onChange={handleFieldChange}
                                                    value={getCustomerDetails.postal_code}
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-6">
                                        <FormControl variant="filled">
                                            <InputLabel id="country-select" required>Country</InputLabel>
                                            <Select
                                                labelId="country-select"
                                                id="country-dropdown"
                                                onChange={handleCountryChange}
                                                value={countrySelect}
                                                label="Country"
                                                validators={[
                                                    "required",
                                                ]}
                                                errorMessages={
                                                    ("Please Fill Country")
                                                }
                                            >
                                                {countryOptions.map((item) => {
                                                    return (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-6">
                                        <TextValidator
                                            validators={[
                                                "required",
                                            ]}
                                            errorMessages={
                                                ("Please Fill State")
                                            }
                                            inputProps={{ maxLength: 20 }}
                                            name="province"
                                            label={getStatePovince}
                                            variant="filled"
                                            onChange={handleFieldChange}
                                            value={getCustomerDetails.province}
                                        />
                                    </div>
                                </div>
                            </ValidatorForm>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12 d-flex justify-content-end">
                                <Button type="onSubmit" onClick={() => closeCustomerDetails()} name="Cancel" className="btn btn-cancle mr-3" />
                                <Button type="onSubmit" onClick={() => createCustomer()} name="Save" className="btn btn-primary" />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Customers;
