import React, { useState, useCallback, useEffect } from "react";
import Button from "../../../../components/button/button";
import backIcon from "../../../../assets/images/arrowLeft.svg";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Tooltip } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./linkBased.scss";
import { useHistory } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import PDF from "../../../../assets/images/pdfHolder.svg";
import { KeyboardTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useDropzone } from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getMethod, postMethodWithTokenProgress, postMethodWithToken, putMethodWithToken } from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import { toast } from 'react-toastify';
import UploadImage from "../../../../assets/images/UploadImage.svg";
import UploadPhotoIcon from "../../../../assets/images/add_a_photo.png";

const LinkBased = (datas) => {
    const history = useHistory();

    const checkCampaign = campaign => {

        const emptyKeys = [
            'name',
            'country',
            'state_id',
            'city_id',
            'bannerRedirecturl',
            'weekday',
            'startDate',
            'endDate',
            'description',
            'bannerImage_id',
        ];

        const emptyKeysErrors = [
            'Name',
            'Country',
            'State',
            'City',
            'Banner URL',
            'Week Days',
            'Start Date',
            'End Date',
            'Description',
            'Banner Image',
        ];

        for (let i = 0; i < emptyKeys.length; i++) {
            if (!campaign[emptyKeys[i]]) {
                return `Please Fill In ${emptyKeysErrors[i]}`;
            }

            if (emptyKeys[i] === 'weekday' && campaign[emptyKeys[i]].length === 0) {
                return `Please Fill In ${emptyKeysErrors[i]}`;
            }
        }

        return '';
    };

    const forceUpdate = useForceUpdate();

    function useForceUpdate() {
        const [value, setValue] = useState(0);
        return () => setValue((value) => value + 1);
    }

    const gotoBack = async () => {
        history.goBack()
    };

    const days = [
        { dayName: "Mo", dayValue: "monday" },
        { dayName: "Tu", dayValue: "tuesday" },
        { dayName: "We", dayValue: "wednesday" },
        { dayName: "Th", dayValue: "thursday" },
        { dayName: "Fr", dayValue: "friday" },
        { dayName: "Sa", dayValue: "saturday" },
        { dayName: "Su", dayValue: "sunday" },
    ];

    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [stateDataList, setStateDataList] = useState([]);
    const [cityDataList, setCityDataList] = useState([]);
    const [countrySelected, setCountrySelected] = useState("");
    const [stateSelected, setStateSelected] = useState("");
    const [citySelected, setCitySelected] = useState("");
    const [selectedDays, setSelectedDays] = useState([]);
    const [docId, setDocID] = useState([]);
    const [uploadedDoc, setUploadedDoc] = useState([]);
    const [uploadedImage, setUploadedImage] = useState("");
    const [uploadedImageId, setUploadedImageId] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [getStatePovince, setStatePovince] = useState("State");

    const [formValue, setFormValue] = useState({
        name: "",
        bannerRedirecturl: "",
        country: "",
        state_id: "",
        city_id: "",
        startDate: "",
        endDate: "",
        description: "",
        weekday: [],
        bannerImage_id: "",
        bannerType: "linked-based",
        isActive: true
    });

    useEffect(() => {
        getCountryList();

        if (datas.id !== 'Create') {
            getCampaignData();
        }
    }, []);

    const getCampaignData = async () => {
        await getMethod(urls.getPromotion + datas.id + '/').then((response) => {

            if (response.status === 200 || response.status === 201) {
                let countryValue = response.data.response.country;
                let stateValue = response.data.response.state;
                let cityValue = response.data.response.city;
                let state_id = '';

                if (countryValue === 'CAN') {
                    setStatePovince("Province");
                } else {
                    setStatePovince("State");
                }

                if (countryValue) {
                    getMethod(urls.getStates + '?country_name=' + countryValue).then((response) => {
                        if (response.status === 200 || response.status === 201) {
                            let results = response.data.response[0].data.states

                            setStateList(results);
                            setStateDataList(results)

                            if (results.length > 0 && stateValue) {
                                let indexStateID = '';

                                results.map((item) => {
                                    if (item.stateName === stateValue) {
                                        indexStateID = item.id;
                                    }
                                });

                                if (indexStateID) {
                                    state_id = indexStateID;
                                    setStateSelected(indexStateID);

                                    setFormValue((prevState) => ({
                                        ...prevState,
                                        state_id: indexStateID,
                                    }));

                                    if (countryValue && state_id) {
                                        getCityListWithName(countryValue, state_id, cityValue)
                                    }
                                }
                            }
                        }
                    });
                }


                setFormValue((prevState) => ({
                    ...formValue,
                    ['name']: response.data.response.name,
                    ['bannerRedirecturl']: response.data.response.bannerRedirecturl,
                    ['description']: response.data.response.description,
                    ['country']: response.data.response.country,
                    ['startDate']: response.data.response.startDate,
                    ['endDate']: response.data.response.endDate,
                }));

                setStartDate(response.data.response.startDate)
                setEndDate(response.data.response.endDate)
                setUploadedImage(response.data.response.bannerImage.file);
                setUploadedImageId(response.data.response.bannerImage.id);

                response.data.response.weekdays.forEach((x, i) => {
                    selectedDays.push(x.slug)
                });

            }
        });
    };

    const getCountryList = async () => {
        await getMethod(urls.getCountry).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setCountryList(response.data);
            }
        });
    };

    const getStateList = async (value) => {
        await getMethod(urls.getStates + '?country_name=' + value).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setStateList(response.data.response[0].data.states);
                setStateDataList(response.data.response[0].data.states);
            }
        });
    };

    const getCityList = async (value) => {
        await getMethod(urls.getStates + '?country_name=' + formValue.country + '&state_id=' + value).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setCityList(response.data.response[0].data.cities);
                setCityDataList(response.data.response[0].data.cities);
            }
        });
    };

    const getCityListWithName = async (countryId, stateId, name) => {
        await getMethod(urls.getStates + '?country_name=' + countryId + '&state_id=' + stateId).then((response) => {
            if (response.status === 200 || response.status === 201) {
                let results = response.data.response[0].data.cities;
                setCityList(results);
                setCityDataList(results);

                if (name && results.length > 0) {
                    let indexCityID = '';

                    results.map((item) => {
                        if (item.cityName === name) {
                            indexCityID = item.id;
                        }
                    });

                    if (indexCityID) {
                        setCitySelected(indexCityID)

                        setFormValue((prevState) => ({
                            ...prevState,
                            city_id: indexCityID,
                        }));
                    }
                }

            }
        });
    }

    const handleCountryChange = (event) => {
        setCountrySelected(event.target.value);
        setFormValue({ ...formValue, [event.target.name]: event.target.value });
        getStateList(event.target.value);

        if (event.target.value === "CAN") {
            setStatePovince("Province");
        } else {
            setStatePovince("State");
        }

        setFormValue((prevState) => ({
            ...prevState,
            state_id: '',
            city_id: '',
        }));

        setStateSelected('')
        setCitySelected('')
    };

    const handleStateChange = (event) => {
        if (event.target.value !== undefined) {
            setStateSelected(event.target.value);
            setFormValue({ ...formValue, [event.target.name]: event.target.value });
            getCityList(event.target.value);

            setFormValue((prevState) => ({
                ...prevState,
                city_id: '',
            }));

            setCitySelected('')
        }
    };

    const handleCityChange = (event) => {
        if (event.target.value !== undefined) {
            setCitySelected(event.target.value);
            setFormValue({ ...formValue, [event.target.name]: event.target.value });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prevState) => ({
            ...formValue,
            [name]: value,
        }));
    };

    const handleStartDateChange = (e) => {
        setFormValue((prevState) => ({
            ...formValue,
            ['startDate']: e,
        }));

        setStartDate(moment(e).format('YYYY-MM-DD'))
    };

    const handleEndDateChange = (e) => {
        setFormValue((prevState) => ({
            ...formValue,
            ['endDate']: e,
        }));

        setEndDate(moment(e).format('YYYY-MM-DD'))
    };

    const selectDays = (value) => {
        if (selectedDays.includes(value)) {
            selectedDays.splice(selectedDays.indexOf(value), 1);
        } else {
            selectedDays.push(value);
        }
        forceUpdate()
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(uploadedDoc);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setUploadedDoc(items);
    };

    const deleteImg = (item, index) => {
        let uploadImgs = uploadedDoc;
        uploadImgs.splice(index, 1);
        let docIds = docId;
        docIds.splice(index, 1);
        setUploadedDoc(uploadImgs);
        setDocID(docIds);
        forceUpdate();
    };

    const checkImage = (item, index) => {
        var fileExtension = item.file.split(".").pop();
        if (fileExtension === "pdf") {
            return (
                <div style={{ marginLeft: "10px", width: "2vw", backgroundColor: "#ECECEC" }}>
                    <img className="uploadedDoc" src={PDF} />
                </div>
            );
        } else {
            return (
                <>
                    <img className="uploadedDoc" src={item.file !== undefined ? item.file : item.url} alt="" />
                </>
            );
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            let formData = new FormData();
            acceptedFiles.map((file, index) => {
                formData.append("file", file);
            });

            postMethodWithTokenProgress(urls.fileUpload, formData).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setUploadedImageId(response.data[0].id)
                    setUploadedImage(response.data[0].file)
                    forceUpdate();
                }

            });
        }
    }, []);

    const onDropRejected = useCallback((files) => {
        if (files[0].errors[0]) {
            toast.error("File Size Must Be Equal Or Greater Than 350*300");
        }
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        onDropRejected,
        accept: "image/*",
        minSize: 349 * 299,
    });

    const handleSubmit = async () => {

        formValue['weekday'] = selectedDays;
        formValue['bannerImage_id'] = uploadedImageId;
        formValue['startDate'] = startDate;
        formValue['endDate'] = endDate;

        const error = checkCampaign(formValue);

        if (error) {
            toast.error(error);
        } else {
            if (datas.id !== 'Create') {
                await putMethodWithToken(urls.putPromotion + datas.id + '/', formValue).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        toast.success(response.data.response)
                        history.push('/campaign')
                    } else {
                        toast.error(response.data.response);
                    }
                });
            } else {
                await postMethodWithToken(urls.postPromotion, formValue).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        toast.success(response.data.response)
                        history.push('/campaign')
                    } else {
                        toast.error(response.data.response);
                    }
                });
            }
        }
    }

    const stateFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = stateDataList.filter((item) => {
                return item.stateName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setStateList(results);
        } else {
            setStateList(stateDataList);
        }
    };

    const cityFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = cityDataList.filter((item) => {
                return item.cityName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setCityList(results);
        } else {
            setCityList(cityDataList);
        }
    };

    return (
        <div style={{ marginBottom: "80px" }}>
            <div className="row pl-3 ml-3 mr-3 mobileResponsive">
                <div onClick={() => gotoBack()} style={{ cursor: "pointer" }}>
                    <ArrowBackIcon /> <a style={{ color: "#4472C4" }} className="ml-2">Listings</a>
                </div>
            </div>
            <div className="row mt-2 ml-3 mr-3 mobileResponsive">
                <h1 className="heading-title col-7">Link based</h1>
                <div className="col-5 d-flex justify-content-end">
                    <Button className="btn btn-primary" name={datas.id !== 'Create' ? 'Update' : 'Create'} onClick={handleSubmit}></Button>
                </div>
            </div>

            <div className="ml-3 mr-3 mt-3 row bodyContainer mobileResponsive">
                <ValidatorForm className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <TextValidator
                                required
                                errorMessages={[
                                    "Please Enter A Campaign Name",
                                ]}
                                validators={[
                                    "required",
                                ]}
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                inputProps={{ maxLength: 20 }}
                                name="name"
                                label="Campaign name"
                                variant="outlined"
                                value={formValue.name}
                            />
                        </div>

                        <div className="col-6">
                            <div className="row">
                                <div className="col-4">
                                    <FormControl variant="outlined">
                                        <InputLabel required id="country-select">Country</InputLabel>
                                        <Select
                                            labelId="country-select"
                                            id="country-dropdown"
                                            value={formValue.country}
                                            onChange={handleCountryChange}
                                            label="Country"
                                            name="country"
                                            errorMessages={["Please Select Country"]}
                                            validators={[
                                                "required",
                                            ]}
                                        >
                                            {countryList.map((item) => {
                                                return (
                                                    <MenuItem value={item.iso_3166_1_a3}> {item.printable_name} </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-4">
                                    <FormControl variant="outlined" className="gray-select">
                                        <InputLabel required id="country-select">{getStatePovince}</InputLabel>
                                        <Select
                                            MenuProps={{ autoFocus: false }}
                                            labelId="country-select"
                                            id="country-dropdown"
                                            value={formValue.state_id}
                                            defaultValue={formValue.state_id}
                                            label={getStatePovince}
                                            name="state_id"
                                            errorMessages={["Please Select State"]}
                                            validators={[
                                                "required",
                                            ]}
                                            onChange={(e) => handleStateChange(e)}
                                            onClose={() => setStateList(stateDataList)}
                                        >
                                            <ListSubheader>
                                                <TextField
                                                    size="small"
                                                    autoFocus
                                                    placeholder="Type to search..."
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={stateFilter}
                                                    onKeyDown={(e) => {
                                                        if (e.key !== "Escape") {
                                                            e.stopPropagation();
                                                        }
                                                    }}
                                                />
                                            </ListSubheader>
                                            {stateList.map((item) => {
                                                return (
                                                    <MenuItem value={item.id}> {item.stateName} </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-4">
                                    <FormControl variant="outlined" className="gray-select">
                                        <InputLabel required id="city-select">City</InputLabel>
                                        <Select
                                            MenuProps={{ autoFocus: false }}
                                            labelId="city-select"
                                            id="city-dropdown"
                                            value={formValue.city_id}
                                            defaultValue={formValue.city_id}
                                            label="City"
                                            name="city_id"
                                            errorMessages={["Please Select City"]}
                                            validators={[
                                                "required",
                                            ]}
                                            onChange={(e) => handleCityChange(e)}
                                            onClose={() => setCityList(cityDataList)}
                                        >
                                            <ListSubheader>
                                                <TextField
                                                    size="small"
                                                    autoFocus
                                                    placeholder="Type to search..."
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={cityFilter}
                                                    onKeyDown={(e) => {
                                                        if (e.key !== "Escape") {
                                                            e.stopPropagation();
                                                        }
                                                    }}
                                                />
                                            </ListSubheader>
                                            {cityList.map((item) => {
                                                return (
                                                    <MenuItem value={item.id}> {item.cityName} </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-6">
                            <p className="subheadings">&nbsp;</p>
                            <div className="row">
                                <div className="col-12">
                                    <TextValidator
                                        required
                                        errorMessages={[
                                            "Please Enter A Link",
                                        ]}
                                        validators={[
                                            "required",
                                        ]}
                                        type="text"
                                        onChange={handleInputChange}
                                        name="bannerRedirecturl"
                                        label="Enter link"
                                        variant="outlined"
                                        value={formValue.bannerRedirecturl}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <p className="subheadings">Select the days:-</p>
                            <div className="row">
                                <div className="col-12" style={{ display: "flex" }}>
                                    {days.map((item, index) => {
                                        return (
                                            <div onClick={() => selectDays(item.dayValue)} className={selectedDays.includes(item.dayValue) ? "linkdaysBlockActive" : "linkdaysBlockInactive"}>{item.dayName}</div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-6">
                            <div>
                                <p className="subheadings">Validity</p>
                                <div className="row">
                                    <div className="col-6">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                InputProps={{ disableUnderline: true }}
                                                className={formValue.startDate !== "" ? "campaignTypeDate input-datepicker selectedDate" : "campaignTypeDate input-datepicker"}
                                                id="date-detail"
                                                variant="outlined"
                                                label="Start Date"
                                                name="startDate"
                                                value={formValue.startDate}
                                                onChange={(date) => handleStartDateChange(date)}
                                                format="yyyy-MM-dd"
                                                error={false}
                                                helperText={null}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-6">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                InputProps={{ disableUnderline: true }}
                                                className={formValue.endDate !== "" ? "campaignTypeDate input-datepicker selectedDate" : "campaignTypeDate input-datepicker"}
                                                id="date-detail"
                                                variant="outlined"
                                                label="End Date"
                                                name="endDate"
                                                value={formValue.endDate}
                                                onChange={(date) => handleEndDateChange(date)}
                                                format="yyyy-MM-dd"
                                                error={false}
                                                helperText={null}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <p className="subheadings">Description</p>
                                <div className="row">
                                    <div className="col-12">
                                        <textarea
                                            className="textarea-input"
                                            onChange={(e) => handleInputChange(e)}
                                            placeholder={"Description"}
                                            name={"description"}
                                            value={formValue.description}
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <div>
                                <p className="subheadings">Upload banner image</p>
                                <div className="row">
                                    <div className="col-12 d-flex">
                                        {uploadedImage &&
                                            <div className="storeImageBlock d-flex mr-3">
                                                <img className="imgTagBlock" src={uploadedImage} />
                                            </div>
                                        }

                                        <div className="storeImageBlock d-flex">
                                            <div className="imageUpload" {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {isDragActive ? (
                                                    <p>Drop the files here ...</p>
                                                ) : (
                                                    <div className="uploadPhoto">
                                                        <div style={{ cursor: "pointer" }} className="uploadInfo">
                                                            <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                            <div className="uploadText">Add Photo</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ValidatorForm>
            </div>
        </div>
    );
};
export default LinkBased;
