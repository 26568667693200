import React from "react";
import Button from "../../components/button/button";
import "./home.scss";
import ShopezLogo from "../../assets/images/shopez_logo.png";
import { useHistory } from "react-router-dom";
import requestImg from "../../assets/images/landing/request.png";
import fillAFormImg from "../../assets/images/landing/fill-a-form.png";
import getApprovedImg from "../../assets/images/landing/get-approved.png";
import earnImg from "../../assets/images/landing/earn.png";

const Home = () => {
    const history = useHistory();

    const login = async () => {
        history.push("/login");
    };
    const createstore = async () => {
        history.push("/createstore");
    };

    return (
        <div>
            <div className="container-fluid p-0">
                <div className="header pt-3 pb-3 pl-5 pr-5">
                    <div className="row">
                        <div className="col-4 d-flex align-items-center">
                            <div className="ml-5">
                                <img width="115" src={ShopezLogo} />
                            </div>
                        </div>
                        <div className="col-8 d-flex justify-content-end">
                            <div className="row header-partner mr-5">
                                {/* <div className="text d-flex align-items-center mr-2">Became a Partner</div> */}
                                <div onClick={() => login()}>
                                    <Button name="Login" className="btn btn-login btn-white" />
                                </div>
                                <div onClick={() => createstore()}>
                                    <Button name="Sign Up" className="btn btn-cancel btn-login" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="banner-image-home">
                <div>
                    <h2 className="img-title">Rev up Your Business with the Power of Digital Innovation</h2>
                    <div className="muiDescripationHomeInfo mt-2">Extend Your Customer Reach,Stremline,Order Processing,Effortless,Track Inventory,and Propel Your Business.</div>
                    <div className="muiDescripationHomeStoreInfo mt-2">Launch Your <span style={{ color: "#d6740b" }}>Shopez</span> Online Store in Minutes</div>
                </div>
            </div>

            <div className="container-fluid mb-5 mt-5">
                <div className="row mb-5">
                    <div className="col-sm-12 col-md-10 offset-md-1">
                        <h2 className="text-center text-step">Steps to Partner With Shopez</h2>
                    </div>
                </div>

                <div className="row img-des">
                    <div className="col-sm-12 col-md-6 col-xl-3 col-3">
                        <img className="" src={requestImg} />
                        <div className="step-info mt-4">
                            <div className="d-flex">
                                <div className="main-round">1</div>
                                <div className="main-heading ml-2">Express Your Interset</div>
                            </div>
                            <div className="main-description mt-2">Share essential store details, including your store name, address, and contact information. Upon review, you'll receive a link to input comprehensive store information for further validation.</div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-xl-3 col-3">
                        <img className="" src={fillAFormImg} />
                        <div className="step-info mt-4">
                            <div className="d-flex">
                                <div className="main-round">2</div>
                                <div className="main-heading ml-2">Complete Business Information</div>
                            </div>
                            <div className="main-description mt-2">Enter your store categories, products, financial. Text details, etc. Additionally, provide business credentials to validate your identity and safeguard your business name from potential misuse.</div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-xl-3 col-3">
                        <img className="" src={getApprovedImg} />
                        <div className="step-info mt-4">
                            <div className="d-flex">
                                <div className="main-round">3</div>
                                <div className="main-heading ml-2">Stock Up Your Online Store</div>
                            </div>
                            <div className="main-description mt-2">Once approved, upload your store products,manage inventory, run periodic promotions, and much more to captivate your existing and prospective customers.</div>
                        </div>
                    </div>
                    {/* <div className="col-sm-12 col-md-6 col-xl-3 col-3">
                                <img className="" src={earnImg} />
                                <div className="step-info mt-4">

                                </div>
                            </div> */}
                    <div className="col-sm-12 col-md-6 col-xl-3 col-3">
                        <img className="" src={earnImg} />
                        <div className="step-info mt-4">
                            <div className="d-flex">
                                <div className="main-round">4</div>
                                <div className="main-heading ml-2">Manage Order and Track Performance</div>
                            </div>
                            <div className="main-description mt-2">Seamlessly handle online orders while tracking essential store metrics such as daily sales, transactions, and net profits.Stay informed to make strategic decisions for optimizing your business performance.</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Home;
