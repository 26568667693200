import React, { useState, useEffect, useRef, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "../../../../components/button/button";
import { getMethod, postMethodWithToken, putMethodWithToken } from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json";
import "./faqs.scss";
import OnHoldModal from "../../../../components/OnHoldModal/onHoldModal";


const checkFaq = data => {

    const emptyKeys = [
        'merchant_question',
        'merchant_description',
    ];

    const emptyKeysErrors = [
        'Question',
        'Answer',
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!data[emptyKeys[i]]) {
            return `Please Fill In ${emptyKeysErrors[i]}`;
        }
    }

    return '';
};

const Faq = (props) => {
    const history = useHistory();
    const params = useParams();
    const editorRef = useRef(null);
    const [modalMe, setModalMe] = useState(false)

    function getDescriptionConfig(props) {
        let config = {
            readonly: false,
            placeholder: "Question Answer",
        }
        return config;
    }

    const descriptionConfig = useMemo(() => getDescriptionConfig());

    const [formValue, setFormValue] = useState({
        merchant_question: "",
        merchant_description: "",
        isDeleted: false,
    });

    useEffect(() => {
        getSteps()
        if (params.id !== 'create') {
            getFaqDetails();
        }
    }, []);

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    }

    const getFaqDetails = async () => {
        await getMethod(urls.getFaqDetails + params.id + "/").then((response) => {
            if (response.status === 200 | response.status === 201) {
                setFormValue((prevState) => ({
                    ...formValue,
                    ['merchant_question']: response.data.response.merchant_question,
                    ['merchant_description']: response.data.response.merchant_description,
                    ['isDeleted']: response.data.response.isDeleted,
                }));
            }
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDescriptionChange = (e) => {
        setFormValue((prevState) => ({
            ...prevState,
            ['merchant_description']: e,
        }));
    };

    const submitData = async () => {
        const error = checkFaq(formValue);

        if (error) {
            toast.error(error);
        } else {
            if (params.id === "create") {
                await postMethodWithToken(urls.postFaqsList, formValue).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        toast.success(response.data.response);
                        history.push('/settings/faqs')
                    } else {
                        toast.error(response.data.response);
                    }
                });
            } else {
                await putMethodWithToken(urls.putFaqsList + params.id + "/", formValue).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        toast.success(response.data.response);
                        history.push('/settings/faqs')
                    } else {
                        toast.error(response.data.response);
                    }
                });
            }
        }
    }

    return (
        <div className={props.menu ? "screenLeftMargin w-100" : "screenLeftMarginResponse w-100"}>
            <div className="row ml-3 mt-3 mr-3">
                <div onClick={() => history.push("/settings/faqs")} style={{ cursor: "pointer" }}>
                    <ArrowBackIcon /> <a style={{ color: "#4472C4" }} className="ml-2">{"FAQ's"}</a>
                </div>
            </div>
            <div className="row ml-3 mt-3 mr-3">
                <div className="col-12"><h1 className="heading-title">{"FAQ"}</h1></div>
            </div>
            <div className="row ml-3 mr-3">
                <div className="col-12">
                    <ValidatorForm>
                        <div className="row mt-3">
                            <div className="col-12">Question</div>
                            <div className="col-12 mt-2">
                                <TextValidator
                                    required
                                    inputProps={{}}
                                    name="merchant_question"
                                    label="Question"
                                    variant="outlined"
                                    value={formValue.merchant_question}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">Answer</div>
                            <div className="col-12 mt-2">
                                <div className={"joditEdit"}>
                                    <JoditEditor
                                        ref={editorRef}
                                        value={formValue.merchant_description}
                                        config={descriptionConfig}
                                        onBlur={newContent => handleDescriptionChange(newContent)}
                                        onChange={newContent => { }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 d-flex justify-content-end">
                                <Button type="onSubmit" onClick={() => history.push("/settings/faqs")} name="Cancel" className="btn btn-cancle mr-3" />
                                <Button type="onSubmit" onClick={() => submitData()} name="Save" className="btn btn-primary" />
                            </div>
                        </div>
                    </ValidatorForm>
                    <OnHoldModal isOnHold={modalMe} />
                </div>
            </div>
        </div>
    )
};
export default Faq;